import React from 'react'
import { useTranslation } from 'react-i18next'
import VisitorLayout from 'src/components/layout/VisitorLayout'
import Heading from 'src/components/typography/Heading'
import TeacherAddDetailsForm from 'src/components/register/TeacherAddDetailsForm'
import heroRegisterTeacher from 'src/images/register/hero-register-teacher.jpg'

export default function AddTeacherDetails(props) {
    const { t } = useTranslation()

    return (
        <VisitorLayout {...props} hero={heroRegisterTeacher} title={t('teacher.addDetails.title')}>
            <div className="px-6 lg:px-0">
                <Heading type='sub-title'>{t('teacher.addDetails.title')}</Heading>
                <TeacherAddDetailsForm />
            </div>
        </VisitorLayout>
    )
}
